@keyframes slideInFromRight {
    0% {
        transform: translateX(100%); /* Start from outside the viewport (right) */
        opacity: 0;
        visibility: hidden; /* Hidden at the start */
    }
    
    100% {
        transform: translateX(0); /* End at the default position */
        opacity: 1; /* Fully visible */
    }
}

@keyframes slideOutToRight {
    0% {
        transform: translateX(0); /* Start at the default position */
        opacity: 1; /* Fully visible */
        visibility: visible;
    }
    100% {
        transform: translateX(100%); /* Move outside the viewport (right) */
        opacity: 0; /* Fully invisible */
        visibility: hidden; /* Hidden at the end */
    }
}



.animateIn {
    animation: slideInFromRight 0.15s ease-in forwards;
    position: relative;
    /* Keep it in the document flow */
    right: 0;
}

.animateOut {
    animation: slideOutToRight 0.15s ease-out forwards;
    position: absolute;
    /* Remove from document flow */
    right: 0;
}

.contact {
    background-color: #F6F6F9;
    max-width: 300px;
    height: 100vh;
}

.closeIcon {
    font-size: 28px;
    cursor: pointer;
    color: #555555;
}

.closeIcon:hover , .edit:hover{
    transform: scale(1.1);
}
.edit:hover{
    color: black;
}

.name-section h4 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

/* .labelContainer:hover {
    background-color: #e8f5e9;
    transform: scale(1.05);
} */

.labelContainer .btn-primary {
    background-color: #28a745;
    border: none;
}

.contact header {
    position: sticky;
    top: 0;
    z-index: 10;
}

.contactDetails {
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.contactDetails header {
    font-size: 1rem;
    font-weight: bold;
}

.contactDetails main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}

.detail:last-child {
    border-bottom: none;
}

.detail label {
    font-size: 0.9rem;
    color: #6c757d;
}

.detail div {
    font-size: 0.9rem;
    color: #212529;
}

.labelText {
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 0.5rem;
}




@media (max-width: 992px) {
    .contact {
        position: absolute;
        right: 0;
        bottom: 0;
        height: -webkit-fill-available;
        z-index: 1000;
        border-radius: 16px 16px 0 0;
        width: 300px;
    }


}