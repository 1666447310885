.agentCard {
    display: flex;
    flex-direction: column;
    background-color: lightgreen;
    font-size: .9rem;
}

.btn {
    cursor: pointer;

}

.agentName {
    color: brown;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text {
    font-size: 0.8rem;
    color: black;
    word-break: break-all;
}

footer {
    font-size: 0.7rem;
}

.max-width-lg {
    max-width: 300px;
}

.formDetailsCard {
    background-color: #f9f9f9;
    /* Light background to resemble form details */
    border: 1px solid #ddd;
    /* Subtle border for clarity */
    border-radius: 8px;
    /* Rounded corners for a modern look */
    padding: .5rem;
    /* Spacing around the content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    font-family: Arial, sans-serif;
    /* Clean font for readability */
    word-break: break-all;
}

.listUnstyled {
    list-style: none;
    /* Remove default list styling */
    padding: 0;
    margin: 0;
}

.formDetailsItem {
    margin-bottom: 8px;
    /* Space between items */
    color: #333;
    font-size: .8rem;
    /* Neutral text color */
}

.formDetailsItem strong {
    color: #000;
    /* Highlighted label color */
}

