.faqContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-height: 100%;
    overflow: hidden;

}

.formCard {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    width: 100%;
}

@media (max-width: 768px) {
    .faqContainer {
        padding: 0.5rem;
        width: 95%;
    }

    .formCard {
        padding: 1rem;
    }
}
.faqPanel{
    width: 40%;
    max-width: 500px;
    height: 100vh;
}
@media (max-width: 992px) {
    .faqPanel {
        position: absolute;
        right: 0;
        top: 0;
        height: -webkit-fill-available;
        z-index: 1000;
        border-radius: 16px 16px 0 0;
        width: 300px;
    }


}