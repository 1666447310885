.modalContent {
  padding: 1.5rem;
  max-width: 600px;
  background-color: whitesmoke;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: bold;
  color: maroon;
}

.modalTitle {
  font-size: 1.3rem;
}

.label {
  background-color: #e58e15;
  color: white;
  margin-left: 1rem;
  padding: .25rem .5rem;
  border-radius: 1rem;
  display: inline-block;
}



.modalFooter {
  display: flex;
  justify-content: flex-end;
}

.scrollableContent {
  max-height: 450px;
  /* Adjust height as needed */
  overflow-y: auto;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f9f9f9;
}
.closeIcon:hover{
  cursor: pointer;
}
.section {
  margin-bottom: 1.5rem;
}

.items {
  margin-top: 0.5rem;
  padding-left: 0;
}

.item {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.agentList {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Space between badges */
}

.agentBadge {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  background-color: #e0f7fa;
  color: #00796b;
  font-size: 0.9rem;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
