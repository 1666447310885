.container{
height: 100vh;
overflow-y: auto;

}

@media (max-width: 993px) {
    .container{
        height: calc(100vh - 65px)
    }
    
}