.autoReplyContainer {
    width: 100%;
    max-width: 90%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 2rem;

}

.headerTitle {
    font-weight: bold;
    /* Bold title text */
    color: #333;
    /* Darker text for better readability */
}

.timeSeparator {
    font-size: 1.25rem;
    /* Slightly larger font size for 'TO' separator */
    font-weight: bold;
    color: #333;
}


.form-check-label {
    cursor: pointer;
    /* Change cursor to pointer when hovering over labels */
}

.card {
    border-radius: 8px;
    /* Slightly rounded corners for the card */
}

.form-select,
.form-control {
    border-radius: 6px;
    /* Rounded input fields */
    border: 1px solid #ccc;
    /* Light gray border for input elements */
    box-shadow: none;
    /* Removes any default input box-shadow */
}

.form-select:focus,
.form-control:focus {
    border-color: #f57c00;
    /* Custom border color on focus */
    box-shadow: 0 0 0 0.2rem rgba(245, 124, 0, 0.25);
    /* Soft orange focus outline */
}

.btn-secondary {
    background-color: #6c757d;
    /* Default gray for Close button */
    border-color: #6c757d;
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}
