.noteCard {
    width: 500px;
    height: 100vh;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* overflow-y: auto; */
    padding: 10px;
}

.noteCard::-webkit-scrollbar {
    width: 6px;
}

.noteCard::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
}

header{
    height: 5vh;
}

/* Title */
.title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

/* Close Icon */
.closeIcon {
    font-size: 28px;
    cursor: pointer;
    color: #555555;
}
.closeIcon:hover {
    transform: scale(1.1);
}
.mainDiv{
    overflow-y: auto;
    height: 90vh;
}
/* Notes List */
.notesContainer {
    height: 70vh;
    overflow-y: auto;
    padding: 5px;
    background-color: #ffffff;
}

.notesContainer::-webkit-scrollbar {
    width: 6px;
}

.notesContainer::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
}

/* Individual Note */
.note {
    padding: 8px 12px;
    border-radius: 6px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    background-color:#f9f9f9 ;
}

.note:hover {
    background-color: #f1f1f1;
}

.textareaMode {
    padding: 0; /* Remove padding during edit mode */
    border: none; /* Optional: Remove border around parent */
    background-color: #f9f9f9; /* Optional: Background during edit */
}

.textarea {
    resize: vertical;
    width: 100%;
    height: 100%;
    border: none; /* Remove default textarea border */
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
    background-color: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.textarea:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Highlight focus */
}

/* Note Metadata */
.noteMeta {
    font-size: 12px;
    color: #888888;
}

/* Action Buttons */
.options {
    margin: .5rem;
    display: flex;
    align-items: center;
}

/* .note:hover .options {
    visibility: visible;
} */

.options:hover {
    cursor: pointer;
}

/* Add Note Container */
.addNoteContainer {
    display: flex;
    max-height: 25vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: .5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Textarea Styling */
.textarea {
    resize: vertical;
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
}

/* Add Button Styling */
.addButton {
    background-color: #007bff;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addButton:hover {
    background-color: #0056b3;
}

@media (max-width:992px) {
   .noteCard{
    position: absolute;
    right: 0;
    bottom: 0;
    height: -webkit-fill-available;
    width: 100%;
    z-index: 1000;
   }
    
}
