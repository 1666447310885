.menuCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 50%; /* Position it at the vertical center of the screen */
    left: 50%; /* Position it at the horizontal center of the screen */
    width: 300px;
    max-height: 400px;
    background-color: #ffffff; /* Light background */
    color: #333333; /* Dark text for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    overflow: hidden;
    transform: translate(-50%, -50%); /* Center it */
    z-index: 1000; 
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Blur effect */
    z-index: 999; /* Ensure it's behind the menu card */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #f4f4f4; /* Light header background */
    width: 100%;
    box-sizing: border-box;
}

.title {
    font-size: 1.2rem;
    margin: 0;
    color: #333333; /* Dark text for the title */
    font-weight: 500;
}

.closeIcon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #333333;
    transition: color 0.2s ease-in-out;
}

.closeIcon:hover {
    color: #ff6666; /* Red color on hover for the close icon */
}

.listContainer {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    overflow-y: auto;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    padding: 8px;
    background-color: #fafafa; /* Light background for each item */
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
}

.listItem:hover {
    background-color: #eaeaea; /* Slightly darker hover background */
    color: #333333; /* Keep text dark on hover */
}

.radioInput {
    accent-color: #007bff; /* Blue radio buttons */
    cursor: pointer;
}
