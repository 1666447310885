.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 2px;
    background-color: #F6F6F9;
    padding-bottom: .5rem;    
}

.button {
    color: #333;
    background-color: transparent;
    border: none;
    font-weight: 500;
}

.button:hover{
    cursor: pointer;
    color: rgb(44, 205, 44);
}

.activeButton {
    color: rgb(33, 122, 33);
    /* Active button color (e.g., blue) */
    border-top: 2px solid rgb(33, 122, 33);
}

.textArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.notesContainer {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f9fa;
    padding: 8px;
}

.selectTemplateButton {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
}

.orText {
    margin: 8px 0;
    color: #666;
    font-size: 14px;
}

.typeMessageLink {
    color: #007bff;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.replyAsButton {
    background-color: #e9ecef;
    color: #333;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

/* @media (max-width:991.98px) {
    .inputContainer{
        position: fixed;
        bottom: 0;
        width: 100%;

    }
    
} */

.suggestionsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; /* Prevent wrapping */
    justify-content: flex-start;
    width: 100%;
    padding: 8px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks inside */
}

.suggestionsContainer::-webkit-scrollbar {
    height: 8px; /* Adjust scrollbar height */
    background-color: #e0e0e0; /* Scrollbar track color */
    border-radius: 4px; /* Round the scrollbar edges */
}

.suggestionsContainer::-webkit-scrollbar-thumb {
    background-color: #131415; /* Scrollbar thumb color */
    border-radius: 4px; /* Round the thumb */
    border: 2px solid #e0e0e0; /* Padding around the thumb */
}

.suggestionsContainer::-webkit-scrollbar-thumb:hover {
    background-color: #212223; /* Darker thumb on hover */
}

/* Fallback for Firefox */
.suggestionsContainer {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #504f4f #e0e0e0; /* Thumb and track colors */
}

.suggestionsList {
    display: flex;
    gap: 8px;
    padding: 0;
    margin: 0;
    list-style: none;
}

.suggestionPill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background-color: lightgray;
    color: black;
    font-size: 14px;
    font-weight: 500;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #ddd;
    white-space: nowrap; /* Prevent breaking text in pills */
}

.suggestionPill:hover {
    background-color: #007bff;
    color: #ffffff;
}
