.overviewCard {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1rem;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
}

.overviewCard:hover {
  transform: scale(1.05);
}

.disabledCard {
  background-color: rgba(211, 211, 211, 0.944);
  color: white;
  text-shadow:1px 1px 2px black;
  cursor: not-allowed;
  transform: none;
}
.disabledCard:hover{
  transform: none;
}
