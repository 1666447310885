/* Card Container */
.replyCard {
    width: 500px;
    height: 100vh;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

/* Title Styling */
.title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

/* Close Icon */
.closeIcon {
    font-size: 28px;
    cursor: pointer;
    color: #555555;
}

.closeIcon:hover {
    transform: scale(1.1);
}

.mainDiv{
    overflow-y: auto;
    height: 90vh;
}

/* Search Bar */
.searchBar {
    padding: 8px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
}

.searchInput {
    border: 1px solid #cccccc;
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
    width: 100%;
}

/* Replies Container */
.repliesContainer {
    height: 48vh;
    overflow-y: auto;

}

/* Section Titles */
.section {
    margin-bottom: 20px;
}

.sectionTitle {
    font-size: 16px;
    font-weight: bold;
    color: #555555;
    margin-bottom: 8px;
    border-bottom: 2px solid #eeeeee;
    padding-bottom: 4px;
}

/* Reply List */
.replyList {
    height: 100%;
    overflow-y: auto;
    padding-right: 8px;
}

.replyList::-webkit-scrollbar {
    width: 6px;
}

.replyList::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
}

/* Individual Reply */
.reply {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 12px;
    transition: all 0.3s ease;
    position: relative;
}

.reply:hover {
    background-color: #f1f1f1;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Reply Content Styling */
.replyContent {
    position: relative; /* Positioning for popup text */
}

/* Reply Textarea */
.reply textarea {
    width: 100%;
    max-height: 100px;
    /* Increased height for better usability */
    font-size: 14px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    padding: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    resize: none;
    /* Allow resizing vertically */
    margin-bottom: 10px;
    /* Space between textarea and buttons */
}



/* Buttons under textarea */
.reply .options button {
    margin-top: 5px;
    margin-right: 8px;
    /* Space between buttons */
    font-size: 14px;
    padding: 6px 12px;
}

/* Buttons visibility */
/* .reply:hover .options {
    visibility: visible;
    display: flex;
    justify-content: flex-end;
} */

/* Adjust reply hover effect when in edit mode */
.reply:hover {
    background-color: #ffffff;
    box-shadow: none;
}

.addReplyContainer {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

textarea.form-control {
    resize: vertical;
    min-height: 100px;
}

button:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
}


/* Options Container for Save/Cancel */
.options {
    display: flex;
    margin: .2rem;
    align-items: center;
    justify-content: flex-end;
}

/* Add Reply Button */
.addButton {
    font-size: 14px;
    font-weight: bold;
}

/* Media Query for Small Screens */
@media (max-width: 992px) {
    .replyCard {
        position: absolute;
        right: 0;
        bottom: 0;
        height: -webkit-fill-available;
        z-index: 1000;
        border-radius: 16px 16px 0 0;
        width: 100%;
    }

    .sectionTitle {
        font-size: 14px;
    }

    .reply textarea {
        min-height: 120px;
        /* Larger height for mobile */
        font-size: 13px;
    }

    .reply .options button {
        font-size: 12px;
        padding: 5px 10px;
    }
}

.titleInput {
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
}

textarea .form-control {
  resize: none;
  min-height: 80px;
}

.titleInput:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Highlight focus */
}

.hoverTitle {
    font-weight: bold;
    color: #333;
    position: relative; /* Ensures the hover effect works correctly */
}

.replyText{
    word-break: break-all;
}



