.settingsMain {
    height: 80vh;
}

.chatSettingsCard {
    max-width: 600px;
    width: 40vw;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    height: 100%;
}

.card {
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 90%;
    margin-bottom: 2rem;
}

.labelCard {
    width: 90%;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
}

.settingsOptions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    transition: background-color 0.3s;
    width: 100%;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

.icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: #28a745;
}

.active {
    background-color: rgb( 78, 172, 109);
    font-weight: bold;
    color: white;
    border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 992px) {
    .chatSettingsCard {
        flex-direction: column;
        width: 100%;
        padding: .5rem;
        max-width: 90%;
        height: auto;
        margin-bottom: 1rem;
    }


    .settingsOptions {
        flex-direction: row;
        gap: 0;
        justify-content: space-between;
        flex-wrap: nowrap;
        background-color: #f8f9fa;
        border-radius: 10px;
    }

    .card {
        width: 90%;
    }
}

.header {
    font-size: 1.25rem;
    /* font-weight: bold; */
    margin-bottom: 1rem;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.input {
    flex: 1;
    border-radius: 5px;
    padding: 0.5rem;
    border: 1px solid #ccc;
}

.addButton {
    flex-shrink: 0;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.labelList {
    display: flex;
    flex-wrap: wrap;
}

.labelContainer {
    display: flex;
    align-items: center;
    padding: 0.3rem 0.7rem;
    border-radius: 16px;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
}

.labelContainer:hover {
    transform: scale(1.05);
}

.labelText {
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 0.5rem;
}

.removeButton {
    background: transparent;
    border: none;
    color: black;
    font-size: 0.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.3s;
}

.removeButton:hover {
    background-color: #f8d7da;
}





/* Responsive Design */
@media (max-width: 992px) {
    .labelCard {
        width: 100%;
        max-width: 90%;
        padding: 1rem;
    }

    .inputGroup {
        gap: 0.75rem;
    }

    .addButton {
        width: 100%;
    }

    .labelContainer {
        padding: 0.3rem 0.5rem;
        font-size: 0.8rem;
    }

    .labelList {
        gap: 0.3rem;
    }
}