/* Reminder Card */
.reminderCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 10px;
    right: 100%;
    width: 17rem;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    padding: 0.8rem;
    z-index: 1000;
}

/* Header Section */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
}

/* Reminder Container */
.reminderContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    max-height: 400px;
}

.date, .reminderName{
    font-size: .7rem;
}



/* Reminder Subcontainer */
.reminderSubCon {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* Section Title */
.sectionTitle {
    font-size: 1rem;
    /* color: #007bff; */
    font-weight: bold;
}

/* Reminder List */
.reminderList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Reminder List Item */
.reminderList li {
    background: #ffffff;
    width: 100%;
    margin-bottom:.5rem ;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;

}

.addButton{
    border-radius: 5px;
    border: none;
    padding: .3rem;
}
.reminderText{
    word-break: break-all;
}

/* .reminderList li:hover .options {
    visibility: visible;
    opacity: 1;
}

.options{
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

} */

/* Hover Effect */
.reminderList li:hover {
    background: #f1f1f1;
    cursor: pointer;
}

/* Highlighted Text */
.reminderList li strong {
    /* color: #007bff; */
    font-weight: bold;
}

.react-datepicker-wrapper {
    width: 100% !important;  /* Use !important to override inline styles */
}